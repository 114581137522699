<template>
  <div
    v-motion
    :initial="{ opacity: 0, scale: 0.95 }"
    :visible="{ opacity: 1, scale: 1 }"
    :delay="100"
    class="card-container"
  >
    <h1>{{ title }}</h1>
    <div class="card-text">
      <p>
        {{ description }}
      </p>
    </div>
    <a :href="link" target="_blank" class="card-button">🔎 View Project</a>
  </div>
</template>

<script>
export default {
  props: ["title", "description", "link"],
};
</script>

<style>
.v-enter-active,
.v-leave-active {
  transition: opacity 1s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
.card-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 80%;
  height: 60%;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 60px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(30px);
  -webkit-backdrop-filter: blur(30px);
  text-align: center;
}

.card-text {
  text-align: left;
  align-self: center;
  width: 80%;
}

.card-container h1 {
  font-size: 80px;
  color: #ffffffcc;
  margin: 40px 0;
}

.card-text p {
  font-size: 30px;
  color: #ffffffcc;
  text-align: justify;
}
.card-button {
  font-size: 25px;
  font-weight: 800;
  color: #ffffffcc;
  border: 1px solid #ffffffcc;
  background: none;
  padding: 10px 25px;
  margin: 60px auto;
  border-radius: 50px;
  cursor: pointer;
  transition: 300ms;
  text-decoration: none;
  word-spacing: 5px;
}

.card-button:hover {
  background: #ffffffcc;
  color: #333;
  transition: 300ms;
}
.card-button:active {
  transform: scale(1);
}
</style>