<template>
  <div class="textblock">
    <h2 id="hi">Hi, I am</h2>
    <h1 id="marco-one-line" class="gradient">Marco Truffat</h1>
    <h1 id="marco-two-line" class="gradient">Marco<br />Truffat</h1>
    <div id="subtitle" class="subtitle">
      <vue-writer
        :array="['Web Developer', 'Vue.js Developer']"
        :eraseSpeed="50"
        :typeSpeed="100"
        :delay="3000"
        :start="0"
      >
        <span class="subtitle">a </span>
      </vue-writer>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
.textblock {
  position: fixed;
  margin-bottom: 120px;
  color: #eee;
  text-align: left;
}
#hi {
  padding-left: 4px;
}
.subtitle {
  font-weight: 400;
  margin-top: 10px;
  padding-left: 4px;
}

.gradient {
  background: -webkit-linear-gradient(315deg, #19ff98 25%, #1940ff);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
/* TYPING ANIMATION */
.is-typed span.cursor {
  display: inline-flex;
  width: 4%;
  height: 2px;
  align-items: flex-end;
  background-color: #bbb;
  animation: blink 1s infinite;
}
</style>