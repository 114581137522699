<template>
  <aside>
    <a
      v-for="(n, index) in 4"
      :key="index"
      :class="{ active: index == currentSection }"
      @click="scrollToElement(index)"
    ></a>
  </aside>
</template>

<script>
import { ref } from "@vue/reactivity";
import { onMounted } from "@vue/runtime-core";

export default {
  setup() {
    const currentSection = ref("");

    const scrollToElement = (id) => {
      document.getElementById(id).scrollIntoView({ behavior: "smooth" });
    };

    onMounted(() => {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.intersectionRatio > 0) {
              currentSection.value = entry.target.getAttribute("id");
            }
          });
        },
        {
          threshold: 0.9,
        }
      );
      document.querySelectorAll(".container section").forEach((section) => {
        observer.observe(section);
      });
    });

    return { currentSection, scrollToElement };
  },
};
</script>

<style scoped>
aside {
  z-index: 1;
  display: flex;
  flex-direction: column;
  position: fixed;

  top: 50%;
  right: 0;
  transform: translate(0, -50%);
}
aside a {
  border: 2px solid #a8a8a8;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  margin: 10px;
  transition: 200ms;
  cursor: pointer;
}
.active {
  transition: 300ms;
  background: #a8a8a8;
}
</style>