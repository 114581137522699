<template>
  <div class="container">
    <section id="0" class="fullpage one">
      <div class="overlay"></div>
      <TextBlock />
      <ScrollIcon @click="two.scrollIntoView({ behavior: 'smooth' })" />
    </section>
    <section id="1" ref="two" class="fullpage">
      <Card
        link="https://online-reaction-timer.web.app"
        title="⏱ Reaction Timer"
        description="A simple tool to measure your reaction time. Click anywhere on the screen to play and use the Stop button to end the game. Created with Vue3 Options
        API. Using: components, lifecycle hooks and custom events."
      />
    </section>
    <section id="2" class="fullpage">
      <Card
        link="https://online-tasks-list.web.app/"
        title="📝 Task List"
        description="Create new tasks, mark as complete, modify existing tasks, filter by status and delete. Created with Vue3 Composition API using Firebase hosting, Firestore, computed properties... "
      />
    </section>
    <section id="3" class="fullpage">
      <Card
        link="https://live-chat-marco.web.app/"
        title="💬 Live Chat"
        description="A simple chatroom. Just create an account and send messages! Created with Vue3 Composition API using Firebase Auth, Firebase hosting, Firestore, Vue Transitions, composables... "
      />
    </section>
  </div>
  <SideNav v-if="showNav" />
</template>

<script>
import ScrollIcon from "./components/ScrollIcon.vue";
import Card from "./components/Card.vue";
import TextBlock from "./components/TextBlock.vue";
import SideNav from "./components/SideNav.vue";
import { ref } from "@vue/reactivity";
import { onMounted } from '@vue/runtime-core';

export default {
  name: "App",
  components: { ScrollIcon, Card, TextBlock, SideNav },
  setup() {
    const two = ref(null);
    const showNav = ref(true);

    const isMobile = () => {
      if (screen.width <= 760) {
        showNav.value = false;
      } else {
        showNav.value = true;
      }
    };

    onMounted(() => {
      isMobile()
    })

    return { two, showNav };
  },
};
</script>

<style>
.container {
  scroll-snap-type: y mandatory;
  overflow-y: overlay;
  height: 100vh;
}
section {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  scroll-snap-align: start;
}
.fullpage {
  height: 100vh;
}
.halfpage {
  height: 50vh;
}
.overlay {
  display: block;
  position: relative;
  height: 100%;
  width: 100%;
  background: none;
  z-index: 1;
}
.one {
  background: rgb(30, 35, 35);
}
.two {
  background: #afa;
}
.three {
  background: #aaf;
}
.four {
  background: #ffa;
}
</style>